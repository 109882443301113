import './App.css';
import Body from './Body';
import Privacy from './Privacy';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
    <div>
      <Routes>
        <Route path='/' index element={<div className="body"> <Body /> </div>} />
        <Route path='/privacy' element={<Privacy />} />
      </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
