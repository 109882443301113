import React from 'react';

const Privacy = () => {
    return(
        <body>
         <h1 style={{color:"#21951B"}} id="privacy-policy">Privacy Policy</h1>
            <p>Last updated: February 09, 2024</p>
            <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
            <p>We’re committed to protecting Your privacy and don&#39;t collect any personal data to provide our software and services (including the apps Weednotes) — referred to as “Services”. Your use of the Services indicates you agree to this policy and our terms of use.</p>
            <h2 style={{color:"#21951B"}} id="interpretation-and-definitions">Interpretation and Definitions</h2>
            <h3 id="interpretation">Interpretation</h3>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            <h3 id="definitions">Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
            <li><p><strong>Application</strong> refers to Weednotes, the software program provided by the Company.</p>
            </li>
            <li><p><strong>Business</strong>, for the purpose of CCPA/CPRA, refers to the Company as the legal entity that collects Consumers&#39; personal information and determines the purposes and means of the processing of Consumers&#39; personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers&#39; personal information, that does business in the State of California.</p>
            </li>
            <li><p><strong>CCPA</strong> and/or <strong>CPRA</strong> refers to the California Consumer Privacy Act (the &quot;CCPA&quot;) as amended by the California Privacy Rights Act of 2020 (the &quot;CPRA&quot;).</p>
            </li>
            <li><p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to CLOUDTREE LLC, 20 S Charles St Ste 403 #1060.</p>
            <p>  For the purpose of the GDPR, the Company is the Data Controller.</p>
            </li>
            <li><p><strong>Consumer</strong>, for the purpose of the CCPA/CPRA, means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.</p>
            </li>
            <li><p><strong>Country</strong> refers to: Maryland,  United States</p>
            </li>
            <li><p><strong>Data Controller</strong>, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.</p>
            </li>
            <li><p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
            </li>
            <li><p><strong>Do Not Track</strong> (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.</p>
            </li>
            <li><p><strong>GDPR</strong> refers to EU General Data Protection Regulation.</p>
            </li>
            <li><p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
            <p>  For the purposes of GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.</p>
            <p>  For the purposes of the CCPA/CPRA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.</p>
            </li>
            <li><p><strong>Service</strong> refers to the Application.</p>
            </li>
            <li><p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
            For the purpose of the GDPR, Service Providers are considered Data Processors.</p>
            </li>
            <li><p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            <p>  Under GDPR, You can be referred to as the Data Subject or as the User as you are the individual using the Service.</p>
            </li>
            </ul>
            <h2 style={{color:"#21951B"}} id="no-use-of-personal-data">No use of Personal Data</h2>
            <p>Nothing in this policy contradicts the following statements:</p>
            <ol>
            <li><p>We don’t collect any of Your Personal Data, including Your IP address, email address, first and or last names, personal aliases, phone numbers, postal address, or any other similiar identifiers. As used in this policy, &quot;Personal Data&quot; is data that can be used to identify a particular person or device.</p>
            </li>
            <li><p>We don’t sell Your personal information to advertisers or other third parties.</p>
            </li>
            <li><p>We retain no personal information nor have we ever received any legal or government demands for user information.</p>
            </li>
            <li><p>We do not collect any information about Your in-app activities, such as page visits and we do not respond to Do Not Track Signals/Settings as we do not collect any information about Your online activities. Further, we don’t allow third parties to collect information about you on our app through tracking or any other means.</p>
            </li>
            <li><p>We do not store or sell your email address or any other personal data you volunteer. Optionally, you may choose to reach out to the company via email communication for questions regarding Our policy and or products.</p>
            </li>
            </ol>
            <h3 id="security-of-our-services">Security of Our Services</h3>
            <p>The security of Our Services is critically important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Our Services, We cannot guarantee its absolute security. Your use of the Services indicates you agree and accept this policy and our terms of use.</p>
            <h2 style={{color:"#21951B"}} id="changes-to-this-privacy-policy">Changes to this Privacy Policy</h2>
            <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
            <p>You will be notified of any policy changes via the &quot;what&#39;s new&quot; section in the respective product stores for both, (Google Play and Apple App Store), prior to any policy changes taking effect. We will also update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
            <h2 style={{color:"#21951B"}} id="contact">Contact</h2>
            <p>You can contact us at <a href="mailto:&#x73;&#117;&#x70;&#112;&#111;&#114;&#x74;&#64;&#109;&#121;&#x77;&#x65;&#101;&#100;&#110;&#x6f;&#116;&#x65;&#x73;&#46;&#99;&#x6f;&#109;">&#x73;&#117;&#x70;&#112;&#111;&#114;&#x74;&#64;&#109;&#121;&#x77;&#x65;&#101;&#100;&#110;&#x6f;&#116;&#x65;&#x73;&#46;&#99;&#x6f;&#109;</a> if you have any questions about this policy.</p>
        </body>
    );
}

export default Privacy;