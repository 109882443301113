import React from 'react';
import {createUseStyles} from 'react-jss';
import './App.css';
import mobile_phone from './images/mobile2.png';
import google_badge from './images/google-play-badge.png';
import ios_badge from './images/ios-badge.png';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

const Body = () => {

    const styles = useStyles();
    return(
        <>
            <div className='entire_body' style={{justifyContent:'center'}}> 
                <div className='body_content' style={{marginTop:'12%',}}>
                    <div className='logo_header'> 
                       
                        <p className='title_size' style={{fontFamily:'Google Sans,Arial,Helvetica,sans-serif', display:'inline-block', color:'gray'}}>
                             <DescriptionRoundedIcon style={{color:'#21951B', width:'3.5rem', height:'3.5rem', marginBottom:-10}} />
                            weednotes
                        </p>
                    </div>
                  
                    <p className='bold_content' style={{marginBottom:5, fontWeight:'bold', fontFamily:'Google Sans,Arial,Helvetica,sans-serif'}}>Keep track of the important things in life</p>
                    <p className='text_content' style={{color:'gray'}}> A new way for you to track, manage, and store your cannabis experiences.</p>
                    <div>
                        <img className='in_image' alt='mobile weednotes' src={mobile_phone} />
                    </div>

                    <div className='badges_container' style={{alignItems:'center', justifyContent:'center', marginTop:10, marginBottom:-15}}>
                        <div>
                            <a href="https://apps.apple.com/us/app/weednotes/id6475650713?itsct=apps_box_badge&amp;itscg=30200">
                                <img className='badges_scale_ios' src={ios_badge}  alt="Download on the App Store" />
                            </a>
                        </div>
                        <div>
                            <a href='https://play.google.com/store/apps/details?id=com.myweednotes&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'> <img className='badges_scale_google'  alt='Get it on Google Play' src={google_badge}/></a>
                        </div>
                    </div>

                    <p style={{fontSize:14, color:'silver'}}>
                        &copy;2024 Weednotes from Cloudtree, LLC. All rights reserved.
                        <a style={{fontSize:14, color:'silver'}} href="/privacy"> Privacy Policy / </a>
                        <a style={{fontSize:14, color:'silver'}} href="mailto:support@myweednotes.com">Contact Us</a>
                    </p>
                </div>
                <div className='image_content'>
                    <img className='image_self' alt='mobile weednotes' src={mobile_phone} />
                </div>
            </div>
        </>
    );

}

const useStyles = createUseStyles({

})

export default Body;